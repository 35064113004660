import LoadingComponent from './loading.vue';

const Loading = {
  install(Vue, options) {
    const LoadingConstructor = Vue.extend(LoadingComponent);
    const instance = new LoadingConstructor({
      el: document.createElement('div'),
      propsData: options
    });
    document.body.appendChild(instance.$el);

    Vue.prototype.$showLoading = () => {
      instance.showLoading();
    };

    Vue.prototype.$hideLoading = () => {
      instance.hideLoading();
    };
  }
};

export default Loading;