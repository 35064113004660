<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted(){

  },
  created () {
    
  }
}
</script>
<style lang="scss">
@import "@/assets/css/public.scss";
.flex{
  display: flex;
}
.flex_center{
  display: flex;
  justify-content: center;
}
.flex_ac{
  display: flex;
  align-items: center;
}
.flex_sb{
  display: flex;
  justify-content: space-between;
}
.flex_row{
  display: flex;
  flex-direction: row;
}
.flex_col{
  display: flex;
  flex-direction: column;
}
.flex_w {
  display: flex;
  flex-wrap:wrap;
}

.line2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 定义文本的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@for $i from 1 through 200{
  .h#{$i}{
    height: 1px * $i;
  }
  .mt-#{$i}{
    margin-top: 1px * $i;
  }
	.mb-#{$i}{
	  margin-bottom: 1px * $i;
	}
	.ml-#{$i}{
	  margin-left: 1px * $i;
	}
	.mr-#{$i}{
	  margin-right: 1px * $i;
	}
}
@for $i from 1 through 100{
  .w#{$i}{
    width: 1% * $i;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.list{
  &::-webkit-scrollbar {
    width: 0;
    width: 6px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0;
    border-radius: 10px;
    background-color: #282932;
    border: 1px solid transparent;
    background-clip: padding-box;
  }
}
</style>
