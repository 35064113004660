const tab = {
  home: 'Trang chủ',
  find: 'Khám phá',
  position: 'Vị trí',
  collect: 'Thu thập',
  my: 'Của tôi'
}
const index = {
  welcome: 'Chào mừng',
  TNkkrich: 'TNkkrich',
  notice: 'Ảnh hưởng của công nghệ NFT Chính sách tài sản kỹ thuật số toàn cầu Tin tức sự kiện NFT Các loại và bước đơn hàng giao dịch NFT',
  PreSale: 'Đang mở bán trước',
  TransactionList: 'Danh sách giao dịch',
  num: 'Số lượng',
  minPrice: 'Giá mua lại tối thiểu',
  nftDetail: 'Chi tiết NFT',
  Countdown: 'Đếm ngược',
  nftInfo: 'Thông tin NFT',
  publisherInfo: 'Thông tin nhà phát hành',
  features: 'Đặc điểm tác phẩm',
  desc: 'Mô tả tác phẩm',
  prePrice: 'Giá bán trước',
  RepurchasePrice: 'Giá mua lại',
  PurchaseType: 'Loại mua',
  oerderNow: 'Đặt hàng ngay',
  myCollect: 'Bộ sưu tập của tôi',
  myCollect1: 'Mảnh ghép của tôi',
  Synthesis: 'Tổng hợp',
  hold: 'Giữ',
  Introduction: 'Giới thiệu',
  ViewTheAuthor: 'Xem tác giả'
}

const find = {
  search: 'Tìm kiếm những điều bạn muốn',
  myCollect: 'Bộ sưu tập của tôi',
  myCollect_desc: 'Triển lãm nghệ thuật NFT đỉnh cao phân mảnh đều có tại TNFT',
  NFTsp: 'Mảnh NFT',
  NFTsp_desc: 'Phân phối NFT bạn sở hữu thành mảnh',
  BecomeAmerchant: 'Trở thành nhà phát hành',
  BecomeAmerchant_desc: 'Trở thành nhà phát hành bạn có thể phát hành NFT của riêng bạn',
  Announcement_headlines: 'Tin tức nổi bật',
  About_NFT: 'Về NFT',
  Creation_Center: 'Trung tâm sáng tạo',
  Synthesis_center: 'Trung tâm tổng hợp',
  issure: 'Cơ quan phát hành'
}

const position = {
  Mypositions: 'Vị trí của tôi',
  PositionMarketValue: 'Giá trị thị trường vị trí (USDT)',
  TodayProfitAndLoss: 'Lãi và lỗ hôm nay',
  TotalProfitAndLoss: 'Lãi và lỗ tổng cộng',
  HoldingCost: 'Chi phí giữ vị trí',
  Number_of_fragments: 'Số lượng mảnh',
  PledgeShards: 'Cầm cố mảnh',
  PledgeIncome: 'Thu nhập cầm cố',
  TNFTRoyaltiesTrade: 'Giao dịch tiền bản quyền TNFT',
  QuickBuy: 'Mua nhanh',
  QuickSell: 'Bán nhanh',
  History: 'Lịch sử',
  name: 'Tên',
  price: 'Giá hiện tại',
  Market: 'Thị trường',
  Pledge: 'Cầm cố',
  TNFT: 'TNFT',
  BuyingPrice: 'Giá mua',
  SellingPrice: 'Giá bán',
  EstimatedBuybackPrice: 'Giá mua lại ước tính',
  RepurchasePriceRatio: 'Tỷ lệ giá mua lại',
  BuyQuantity: 'Số lượng mua',
  SellQuantity: 'Số lượng bán',
  placeholder1: 'Vui lòng nhập số lượng mua',
  placeholder2: 'Vui lòng nhập số lượng bán',
  submit: 'Gửi',
  OTCwallet: 'Ví OTC',
  SellToOTCbalance: 'Bán vào số dư OTC',
  trxnumber:'Số TRX',
  onSale:'Danh sách chờ',
  sellingScope:'Phạm vi bán',
  hangingOrderPrice:'Đang chờ giá',
  surplus:'Phần còn lại',
  withdraw:'Rút tiền',
  completed:'Đã hoàn thành',
  withdrawn:'Đã rút',
  sellOut:'Bán hàng',
  purchase:'Mua vào',
  comfirmTips:'Xác nhận hủy bỏ?',
  confirm:'Xác nhận',
  cancel:'Hủy bỏ',
  unlimited:'Không giới hạn',
  acquisition:'Mua lại'
}

const order = {
  orderHistory: 'Lịch sử đơn hàng',
  all: 'Tất cả',
  inProgress: 'Đang tiến hành',
  expired: 'Đã hết hạn',
  completed: 'Đã hoàn thành',
  orderID: 'Mã đơn hàng',
  Payable: 'Cần thanh toán',
  buy: 'Mua',
  viewNow: 'Xem ngay',
  PaymentDetails: 'Chi tiết thanh toán',
  PurchaseType: 'Loại mua',
  OrderTime: 'Thời gian đặt hàng',
  State: 'Trạng thái'
}

const login = {
  TNFT_account_login: 'Đăng nhập tài khoản TNFT',
  phone: 'Số điện thoại',
  mail: 'Email',
  pwd: 'Mật khẩu',
  placeholder1: 'Vui lòng nhập số điện thoại',
  placeholder2: 'Vui lòng nhập email',
  placeholder3: 'Vui lòng nhập mật khẩu',
  placeholder4: 'Vui lòng nhập mã xác nhận',
  placeholder5: 'Vui lòng nhập mã giới thiệu',
  forgotPwd: 'Quên mật khẩu?',
  forgotPwd1: 'Quên mật khẩu',
  Reset_password: 'Đặt lại mật khẩu',
  login: 'Đăng nhập',
  Dont_have_an_account_yet: 'Chưa có tài khoản?',
  Register_now: 'Đăng ký ngay',
  Register: 'Đăng ký',
  VerificationCode: 'Mã xác nhận',
  ConfirmPassword: 'Xác nhận mật khẩu',
  getCode: 'Lấy mã',
  send: 'Gửi',
  Referral_Code: 'Mã giới thiệu',
  by: 'Bằng cách nhấp vào đăng ký nghĩa là tôi đã đọc và đồng ý với',
  User_Agreement: 'Thỏa thuận người dùng',
  submit: 'Xác nhận'
}

const share = {
  My_invitation_code: 'Mã mời của tôi',
  desc: 'Quét để chơi TNFT, không có phí bản quyền giao dịch, và nhiều phí bản quyền cho các đề xuất!',
  save: 'Lưu mã QR'
}

const my = {
  Merchant: 'Thương gia',
  OTCwallet: 'Ví OTC',
  myCollect: 'Bộ sưu tập của tôi',
  myNFT: 'NFT của tôi',
  Fragments: 'Mảnh',
  OTCbalance: 'Số dư OTC',
  Exchange: 'Đổi tiền bản quyền',
  Transfer_to_NFT: 'Chuyển vào NFT',
  Staking_TNFT: 'Đặt cược TNFT',
  Account_binding: 'Liên kết tài khoản',
  Invite_members: 'Mời thành viên',
  Contact_Customer_Service: 'Liên hệ dịch vụ khách hàng',
  sign_out: 'Đăng xuất',
  shiming_auth:'本名預金',
  Apply_to_be_a_merchant: 'Đăng ký trở thành thương gia',
  Company_Name: 'Tên công ty',
  p_e_Company_Name: 'Vui lòng nhập tên công ty',
  Country: 'Quốc gia',
  p_e_Country: 'Vui lòng nhập quốc gia',
  Introduction: 'Giới thiệu',
  p_e_Introduction: 'Vui lòng nhập giới thiệu',
  Business_license: 'Giấy phép kinh doanh',
  Front_side_of_legal_person_certificate: 'Mặt trước của giấy chứng nhận pháp nhân',
  The_reverse_side_of_the_legal_person_certificate: 'Mặt sau của giấy chứng nhận pháp nhân',

  Apply_to_be_a_auth:'Yêu cầu xác thực tên thật',
  auth_realname:'Tên',
  auth_idcard:'Số ID',
  bank_name:'Bank name',
  bank_account:'Tên tài khoản ngân hàng',
  bank_card:'Số tài khoản ngân hàng',
  Front_side_of_certificate:'Mặt trước của thẻ ID',
  The_reverse_side_of_certificate:'Thẻ ID ngược',
  p_auth_realname: 'Vui lòng nhập tên',
  p_auth_idcard: 'Vui lòng nhập số ID',
  p_bank_name:'Vui lòng nhập tên ngân hàng',
  p_bank_account:'Vui lòng nhập tên tài khoản ngân hàng',
  p_bank_card:'Vui lòng nhập số tài khoản ngân hàng',

  Submit: 'Gửi',
  reSubmit:'Gửi lại',

  Edit_information: 'Chỉnh sửa thông tin',
  avatar: 'Hình đại diện',
  Nickname: 'Tên thật',
  Account: 'Tài khoản',
  Referral_code: 'Mã giới thiệu',
  Credit_score: 'Điểm tín dụng',
  SecuritySettings: 'Cài đặt bảo mật',
  Transaction_password: 'Mật khẩu giao dịch',
  New_Transaction_password: 'Mật khẩu giao dịch mới',
  Login_password: 'Mật khẩu đăng nhập',
  New_Login_password: 'Mật khẩu đăng nhập mới',
  ConfirmPassword: 'Xác nhận mật khẩu',
  p_e_Nickname: 'Vui lòng nhập tên',
  p_e_Password: 'Vui lòng nhập mật khẩu',

  Redeem_NFT: 'Đổi NFT',
  Synthesis: 'Tổng hợp',
  price: 'Giá',
  Number_of_holdings: 'Số lượng nắm giữ',
  Price_trend: 'Xu hướng giá',
  Buy: 'Mua',
  Sell: 'Bán',
  OTCtrading: 'Giao dịch OTC',
  OTC_quick_transaction: 'Giao dịch nhanh OTC',
  OTC_desc: 'Giao dịch nhanh cho phép bạn hoàn tất giao dịch với giá tốt nhất trên thị trường OTC hiện tại',
  I_want_to_pay: 'Tôi muốn thanh toán',
  I_will_receive: 'Tôi sẽ nhận',
  Proposed_price: 'Giá đề xuất',
  How_to_use_OTC: 'Cách sử dụng OTC',
  OTCdesc1: '1. Trước khi mua tiền kỹ thuật số, trước tiên bạn cần đăng ký tài khoản OTC. Xác minh tài khoản của bạn qua địa chỉ email được cung cấp hoặc mã xác nhận nhận được trên điện thoại di động của bạn, hoặc đăng ký trực tiếp bằng tài khoản TNFT.',
  OTCdesc2: '2. Để đảm bảo an toàn và tuân thủ của các giao dịch, vui lòng cung cấp giấy tờ tùy thân để xác minh danh tính. Nền tảng sẽ xem xét thông tin danh tính bạn cung cấp. Quá trình này có thể mất một thời gian, vì vậy hãy kiên nhẫn.',
  OTCdesc3: '3. Sau khi hoàn tất đăng ký tài khoản và vượt qua xác minh danh tính, bạn có thể bắt đầu mua tiền kỹ thuật số. Vào trang mua và chọn loại tiền kỹ thuật số bạn muốn mua. Nhập số lượng mua và xác nhận giao dịch. Luôn kiểm tra tất cả các chi tiết của đơn đặt hàng trước khi xác nhận giao dịch. Khi đơn đặt hàng của bạn khớp và thanh toán hoàn tất, chờ đợi tiền kỹ thuật số được chuyển vào tài khoản OTC của bạn.',

  OTCrecords: 'Ghi chép OTC',
  Unit_price: 'Đơn giá',
  Quantity: 'Số lượng',
  Approved: 'Đã phê duyệt',
  Cancelled: 'Đã hủy',
  NFT_transfer_record: 'Ghi chép chuyển NFT',

  NFT_transfer_in: 'Chuyển NFT vào',
  Category: 'Danh mục',
  address: 'Địa chỉ',
  p_e_address: 'Vui lòng nhập địa chỉ',
  NFTname: 'Tên NFT',
  Features: 'Đặc điểm',
  Description: 'Mô tả',
  p_e_name: 'Vui lòng nhập tên',
  p_e_Features: 'Vui lòng nhập đặc điểm',
  p_e_Description: 'Vui lòng nhập mô tả',
  Upload_NFT_file: 'Tải lên tệp NFT',
  Upload_transfer_certificate: 'Tải lên giấy chứng nhận chuyển nhượng',
  copy_success: 'Sao chép thành công',
  Confirm: 'Xác nhận',
  Pledge: 'Cầm cố',
  Hold: 'Giữ',
  Pre_sale_price: 'Giá bán trước',
  Time_day: 'Thời gian (ngày)',
  Pledge_quantity: 'Số lượng cầm cố',
  p_e_quantity: 'Vui lòng nhập số lượng',
  Quantity_Available: 'Số lượng có sẵn',
  all: 'Tất cả',
  Fragment_reward_days: 'Ngày thưởng mảnh',
  Remark: 'Ghi chú',
  Pledge_time: 'Thời gian cầm cố',
  Interest_accrual_time: 'Thời gian tính lãi',
  Redemption_time: 'Thời gian chuộc lại',
  Award: 'Thưởng',
  Creation_time: 'Thời gian tạo',
  End_time: 'Thời gian kết thúc',
  Unpledge: 'Hủy cầm cố',
  Team_list: 'Danh sách đội',
  account: 'Tài khoản',
  zctime: 'Thời gian đăng ký',
  Royalty_Details: 'Chi tiết tiền bản quyền',
  income: 'Thu nhập',
  spending: 'Chi tiêu',
  Bind_account: 'Liên kết tài khoản',
  Please_enter_the_transaction_password: 'Vui lòng nhập mật khẩu giao dịch',
  noSart: 'Chưa mở cho thành viên thông thường',
  search: 'Tìm kiếm',
  latest: 'Mới nhất',
  hottest: 'Hot nhất',
  p_e_search: 'Vui lòng nhập từ khóa',
  Application_Notes: 'Ghi chú ứng dụng',
  issuer_desc: 'Để đăng ký trở thành nhà phát hành NFT, bạn cần có các điều kiện hợp pháp tương ứng về blockchain. Để biết chi tiết, vui lòng liên hệ dịch vụ khách hàng chính thức hoặc email',
  start: 'Bắt đầu',

  Make_NFT_fragments: 'Tạo mảnh NFT',
  NFTfile: 'Tệp NFT',
  Purchase_limit: 'Giới hạn mua',
  Purchase_limit_desc: 'Giới hạn mua mỗi người',
  Currency: 'Tiền tệ',
  Buyback_price: 'Giá mua lại',
  countDown: 'Đếm ngược (giờ)',
  Transaction_object_ID: 'ID đối tượng giao dịch',
  Click_to_select_NFT_file: 'Chọn tệp NFT',
  Click_to_select_Currency: 'Chọn tiền tệ',
  Click_to_select_countDown: 'Chọn thời gian đếm ngược',
  p_e_price: 'Vui lòng nhập giá',
  create_now: 'Tạo ngay',
  Release_record: 'Ghi chép phát hành',
  feeDesc: 'Phí xử lý rút tiền',
  feeDesc2: 'Sẽ bị trừ phí dịch vụ',
}
const nft ={
  "collect": "Bộ sưu tập",
  "transfer": "Chuyển NFT",
  "pledgeRecord": "Ghi chép cam kết mảnh",
  "hangout": "Danh sách",
  "buytitle": "Tôi muốn mua",
  "buy": "Mua",
  "sell": "Bán",
  "selltitle": "Tôi muốn bán",
  "listcount": "Số lượng danh sách",
  "owner": "Chủ sở hữu",
  "between": "Phạm vi",
  "remains": "Còn lại",
  "hangoutlabel": ["Hiện đang nắm giữ", "Tổng số NFT", "Loại", "Giá trước bán", "Giá",
    "Số lượng", "Giới hạn mua mỗi lần", "Tối đa", "Bán", "Mua"],
  "hangplaceholder": ["Vui lòng nhập giá danh sách", "Vui lòng nhập số lượng",
    "", "Vui lòng nhập số lượng tối đa"],
  holdnumber:'Số lượng nắm giữ',
  available_number:'Số lượng có sẵn',
  hangoutType:["Đơn hàng thường","Đơn hàng phúc lợi","ID người dùng chỉ định","Vui lòng nhập ID người dùng chỉ định"]
}

const otc = {
  orderID: "Mã đơn hàng",
  amount: "Số tiền",
  addtime: "Thời gian",
  detailtitle: "Thông tin giao dịch",
  logintips: [
    "Thông tin của bạn được bảo vệ bởi SGC 256-bit",
    "Sử dụng mã hóa để truyền tải an toàn"
  ],
  detail: ["Giá", "Số lượng giao dịch", "Số tiền thanh toán", "Mã đơn hàng", "Thời gian", "Vui lòng liên hệ dịch vụ khách hàng"]
}

const onekey = {
  royalties: "Tiền bản quyền",
  hold: "Số lượng nắm giữ",
  all: "Tất cả",
  selltips: [
    "Tiền bản quyền NFT là một cách đơn giản và dễ dàng để kiếm lợi nhuận từ công sức của bạn.",
    "Tiền bản quyền NFT mang lại cơ hội tuyệt vời cho các nghệ sĩ, nhà phát triển trò chơi và người sáng tạo nội dung để kiếm lợi nhuận từ các giao dịch thứ cấp theo cách họ chưa từng có trước đây.",
    "Bạn có thể kiếm lợi nhuận từ việc mua và bán tiền bản quyền của mình thông qua TNFT Royalties Trading Express."
  ],
}

const order2 = {
  presale: "Bán trước",
  otc: "Số dư OTC",
  pay: "Thanh toán",
  c2c: "Chuyển khoản C2C",
  waitconfirm: "Chờ xác nhận",
  pass: "Đã thông qua",
  c2ctip: "C2C yêu cầu bạn chuyển khoản thủ công",
  status: ["", "Chờ thanh toán", "Chờ xác nhận", "Hoàn thành", "Hết giờ"],
  confirm: ["Xác nhận đặt hàng", "Bán trước đang diễn ra, vui lòng hoàn thành đơn hàng trong thời gian quy định và thanh toán cho người bán"],
  c2cpay: ["Hỗ trợ tiền tệ", "Địa chỉ", "Số tiền thanh toán", "Tải lên chứng từ",'Chứng từ thanh toán'],
  position:['Số lượng nắm giữ',"Vui lòng hoàn thành giao dịch với số tiền quy định trước"],
  otctip:'OTC-pay sẽ sử dụng số dư của bạn để thanh toán, vui lòng xác nhận',
  missingfund:'Thiếu mật khẩu giao dịch',
  setfundtip:'Bạn chưa thiết lập mật khẩu giao dịch. Vui lòng thiết lập mật khẩu giao dịch trước.'

}


export {
  tab,
  index,
  find,
  position,
  order,
  login,
  share,
  my,
  nft,
  otc,
  onekey,
  order2
}
