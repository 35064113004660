<template>
  <div v-if="isLoading" class="loading-overlay">
    <van-loading color="#1BAF8B"/>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    }
  }
};
</script>
 
<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保加载动画在其他内容之上 */
}
</style>